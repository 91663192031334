<template lang="pug">
.check
  input.check__input(
    v-show="false"
    :id="id"
    type="checkbox"
    v-model="checkedField"
    @change="doCheck"
  )
  label.check__label(
    :for="id"
  )
    FontAwesomeIcon.check__icon(
      v-if="checkedField"
      :icon="['fas', 'check']"
    )
  span.check__text(
    v-if="value.text"
  ) {{ value.text }}
</template>

<script>

export default {
  name: 'Select',
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {
        return { text: '' }
      }
    }
  },
  data: () => ({
    checkedField: false,
    id: ''
  }),
  mounted () {
    this.id = `input-${Math.random().toString().substr(-7)}`
    this.checkedField = this.checked
  },
  methods: {
    doCheck () {
      this.$emit('change', { checked: this.checkedField, value: this.value })
    }
  }
}
</script>

<style lang="sass" scoped>
.check
  display: flex
  align-items: center
  justify-content: center
  &__label
    display: flex
    align-items: center
    justify-content: center
    height: 18px
    width: 18px
    border: 2px solid black
    border-radius: 100%
    margin-right: 12px
  &__icon
    font-size: 12px
  &__text
    margin: 0
    font-size: 16px
    font-style: normal
    font-weight: 400
    line-height: 40px
    letter-spacing: 0em
</style>
